<template>
  <div class="home_header_box">
    <div class="home_header">
      <router-link to="/"><img src="/images/logo_1.jpg" style="height:60px"></router-link>
      <template v-if="route.meta.page_type !== 'user'">
        <nav-item />
      </template>
      <template v-else>
        <div class="song">
          <div class="text">会员中心</div>
          <search />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import navItem from './navItem'
import search from '@/components/search'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
</script>

<style scoped lang="less">
.nav_pop_driver {
  width: 285px;
  height: 56px;
  display: flex;
  align-items: center;

  .item {
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .item:first-child {
    border-right: 1px solid #f2f2f2;
  }
}

.outicon {
  width: 13px;
  height: 15px;
  margin-bottom: 3px;
}

.nav_pop_content {
  width: 396px;
  height: 96px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 12px;

  .item {
    width: 130px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

.home_header_box {
  width: 100%;
  height: 80px;
  background: #fff;

  .home_header {
    width: 1205px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .home_header_layout {
      display: flex;
      align-items: center;
      margin-left: 30px;

      .item {
        margin-right: 40px;

        a {
          color: #1f1f1f;
          font-weight: 700;
          font-size: 16px;

          :active {
            color: #0A6AB9 !important;
          }
        }
      }

    }
  }
}</style>

<style lang="less">
.song {
  display: flex;
  align-items: center;
  .text {
    display: flex;
    align-items: center;
    height: 30px;
    margin-right: 95px;
    margin-left: 17px;
    padding-left: 15px;
    font-size: 18px;
    color: @color_main;
    font-weight: 700;
    border-left: 2px solid @color_main;
  }
  .dressing_screening {
    background-color: #fff !important;
    .lease_input {
      width: auto !important;
      padding: 0 !important;
    }
  }
}
</style>