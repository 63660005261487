<template>
  <!-- 底部开始 -->
  <div class="footer">
    <div class="footer_main_layout">
      <div class="index_main">
        <div class="foot_content">
          <div class="foot_content_left">
            <div>
              <img src="/images/logo.png" style="width:160px;margin-right: 70px;">
            </div>
            <div v-for="(item, index) in store.navList" :key="index" class="foot_content_item">
              <b>{{ item.name }}</b>
              <template v-for="(v, k) in item.items" :key="k">
                <a :href="`/#/helpdocu/index?id=${v.id}`"><span>{{ v.name }}</span></a>
              </template>
            </div>
            <div class="foot_content_item">
              <b>服务热线</b>
              <span>{{ store.serverInfo.platform_customer_service }}</span>
              <span>{{ store.serverInfo.customer_service_time }}</span>
            </div>
          </div>
          <div class="foot_code_box" style="display: flex;align-items: baseline;">
            <div class="code_item" style="margin-right: 20px;">
              <img src="/images/cdyz.png">
              <span>楚道云租公众号</span>
            </div>
            <div class="code_item">
              <img src="/images/applet.jpg">
              <span>楚道云租小程序</span>
            </div>
          </div>
        </div>
        <div class="footer_text_box">
          <div class="footer_text">
            Copyright © 2023 楚道云租 <a class="margin_footer_link" target="_blank" href="https://beian.miit.gov.cn">鄂ICP备18014997号-4</a> <a
              class="margin_footer_link"
              href="https://beian.mps.gov.cn/#/query/webSearch?code=42010202002798"
              rel="noreferrer"
              target="_blank"
            >鄂公网安备42010202002798</a>
          </div>
          <!-- <div class="footer_text footer_text_bottom">
            <span>北京慧珠网络科技有限公司旗下网站 </span>
            <a class="margin_footer_link" target="_blank" href="http://beian.miit.gov.cn/">京ICP备2022001022号-1</a>
            <a class="footer_beian_link" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14019202000925">
              <img src="/images/beian1.png" class="beian_img">
              <span>京公网安备 14019202000925号</span>
            </a>
            <a class="margin_footer_link" target="_blank" href="/images/company_info.png">企业信用信息</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { mainStore } from '@/store'
const store = mainStore()

</script>

<style scoped>
.beian_img {
  width: 20px;height: 20px;
  margin: 0 6px;
  margin-top: -2px;
  margin-left: 7px;
}
.footer_beian_link {
  display: flex;
  align-items: center;
}
.margin_footer_link {
  margin-left: 6px;
}
.footer {
  margin-top: 90px;
}
.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.banner_box {
  display: block;
  width: 1920px;
  position: absolute;
  left: 50%;
  margin-left: -960px;
}
.banner .banner_box .banner_item {
  width: 100%;
  position: relative;
}
.footer_main_layout {
  background: #313740;
}
.index_main {
  width: 1200px;
  margin: 0 auto;
}
.footer_logo {
  width: 100%;
  height: 80px;
  padding-top: 30px;
  border-bottom: 2px solid #888;
}
.foot_content {
  display: flex;
  color: #fff;
  margin-top: 25px;
  justify-content: space-between;
  padding-top: 60px;
}
.foot_content_left {
  display: flex;
}
.foot_content_item {
  display: flex;
  flex-direction: column;
  margin-right: 48px;
}
.foot_content_item * {
  margin-bottom: 18px;
}
.foot_content_item b {
  font-size: 18px;
}
.foot_content_item span {
  color: #ffffffcc;
  font-size: 14px;
}
.foot_content_item a {
  color: #ffffffcc;
  font-size: 14px;
}
.foot_code_box {
  display: flex;
}
.code_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code_item:first-child {
  margin-right: 50px;
}
.code_item span {
  font-size: 14px;
  margin-top: 10px;
}
.code_item img {
  width: 96px;
  height: 96px;
}
.footer_text_box {
  margin-top: 80px;
}
.footer_text {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding-bottom: 10px;
}
.footer_text_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_text a {
  color: #fff;
}
</style>