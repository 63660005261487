import { defineStore } from 'pinia'
import { getHomeCity, homeIndexCustomer, homeIndexBottomNav } from '@/api/home'
import { homeUserIndex, homeEquipTree } from '@/api/post'

export const mainStore = defineStore('main', {
  state: () => {
    return {
      authForm: {
        type: 2,
        area: []
      },
      receiveForm: {},
      userInfo: {},
      cityData: [],
      equipData: [],
      navList: [],
      serverInfo: {}
    }
  },
  getters: {

  },
  actions: {
    getEquipData() {
      homeEquipTree().then(res => {
        this.equipData = res.data
      })
    },
    getCityData() {
      getHomeCity().then(res => {
        this.cityData = res.data
      })
    },
    getUserInfo() {
      homeUserIndex().then(res => {
        this.userInfo = res.data
      })
    },
    getFootList() {
      homeIndexBottomNav().then(res => {
        this.navList = res.data
      })
    },
    getServerInfo() {
      homeIndexCustomer().then(res => {
        this.serverInfo = res.data
      })
    },
    resetAuthForm() {
      this.authForm = {
        type: 2,
        area: []
      }
    }
  }
})