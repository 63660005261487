import Cookies from 'js-cookie'
import _ from 'lodash'

const TokenKey = 'token'

export function getToken() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
  // return Cookies.set(TokenKey, token, { domain: process.env.VUE_APP_ROOT })
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
  // return Cookies.remove(TokenKey, { domain: process.env.VUE_APP_ROOT })
}

export function getUserInfo() {
  return Cookies.get('user')
}

export function setUserInfo(data) {
  return Cookies.set('user', data)
}
export function formatIdCard(idCard) {
  return idCard.replace(/(\d{6})\d{9}([\d|X]{3}|[\d|X]{1})/, '$1***********$2')
}
/**
 * 手机号中间四位转星号
 */
export function phoneNumberHide(tel) {
  tel = '' + tel
  // return tel.replace(tel.substring(3, 7), '****')
  // TODO 隐藏手机中间四位 修改
  return tel.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}
export function toThousands(num) {
  if (!num) {
    return '0.00'
  }
  if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(num)) {
    return num
  }
  var a = RegExp.$1; var b = RegExp.$2; var c = RegExp.$3
  var re = new RegExp().compile('(\\d)(\\d{3})(,|$)')
  while (re.test(b)) {
    b = b.replace(re, '$1,$2$3')
  }
  return a + '' + b + '' + c
}
/**
 * 将数组按 a-z 排序
 * 返回格式 { segs:[{ initial: 'a-z', data: [原数据 1, 原数据 2...] }] }
 * 参数格式 [{titleName: 'xxx'}]
 */
export function azSort(arr) {
  if (arr.length === 0) return
  if (!String.prototype.localeCompare) return null
  var letters = '*ABCDEFGHJKLMNOPQRSTWXYZ'.split('')
  var zh = '阿八嚓哒妸发旮哈讥咔垃痳拏噢妑七呥扨它穵夕丫帀'.split('')
  var segs = [] // 存放数据
  var res = {}
  let curr
  var re = /[^\u4e00-\u9fa5]/// 中文正则
  var pattern = new RegExp('[`\\-~!@#$^&*()=|{}\':;\',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“\'。，、？12345678990]') // 特殊符号

  letters.filter((items, i) => {
    curr = {
      initial: '', // 字母
      data: []  // 数据
    }
    arr.map((v, index) => {
      // 特殊字符
      if (pattern.test(v.titleName[0])) {
        if ((!zh[i - 1] || zh[i - 1].localeCompare(v.titleName) <= 0) && v.titleName.localeCompare(zh[i]) === -1) {
          curr.data.push(v)
        }
      }
      // 判断首个字是否是中文 
      if (re.test(v.titleName[0])) {
        // 英文 
        if (v.titleName[0].toUpperCase() === items) {
          curr.data.push(v)
        }
      } else {
        // 中文
        if ((!zh[i - 1] || zh[i - 1].localeCompare(v.titleName) <= 0) && v.titleName.localeCompare(zh[i]) === -1) {
          curr.data.push(v)
        }
      }
    })
    if (curr.data.length) {
      curr.initial = letters[i]
      segs.push(curr)
      curr.data.sort((a, b) => {
        return a.titleName.localeCompare(b.titleName)
      })
    }
  })
  res.segs = Array.from(new Set(segs))// 去重
  if (res.segs && res.segs[0].initial === '*') {
    res.segs[0].data.sort((a, b) => {
      return _.isFinite(parseInt(b.titleName)) ? 1 : -1
    })
    res.segs.push(res.segs.shift())
  }
  return res
}