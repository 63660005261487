<template>
  <div class="nav_bar_img">
    <img src="/images/return.png" @click="returnFun">
    <img src="/images/refresh.png" class="margin-left" @click="refresh">
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'
const router = useRouter()
onMounted(() => {

})
function refresh() {
  router.go(0)
}
function returnFun() {
  router.go(-1)
}
</script>
<style scoped lang="less">
.nav_bar_img {
  img {
    width: 20px;
    cursor: pointer;
  }
}
</style>