<template>
  <topbar />
  <navbar />
  <div class="bread_box margin-top">
    <div class="bread">
      <a-breadcrumb :routes="routes">
        <template #itemRender="{ route }">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{ route.breadcrumbName }}
          </span>
          <router-link v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
      <back />
    </div>
  </div>
  <div v-if="route2.meta.text" class="nav_bar">
    <div>
      <b>{{ route2.meta.text }}</b>
      <span v-if="route2.meta.type === 'form'" class="margin-left-xs">
        <span class="text-grey font-12">带<span class="text-red">*</span>号为必填</span>
      </span>
    </div>
    <!-- <div class="nav_bar_img">
      <img src="/images/return.png" @click="returnFun">
      <img src="/images/refresh.png" class="margin-left" @click="refresh">
    </div> -->
  </div>
  <div class="layouts">
    <router-view :key="route2.fullPath" />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import topbar from './components/topbar'
import navbar from './components/navbar'
import back from './components/back'

const router = useRouter()
const route2 = useRoute()
const routes = ref([
  {
    path: '/dash/index',
    breadcrumbName: '会员中心'
  },
  {
    path: '/talk/index',
    breadcrumbName: '询价管理',
    children: [
      {
        path: '/dash/index',
        breadcrumbName: '工作台'
      },
      {
        path: '/post',
        breadcrumbName: '我的发布'
      }
    ]
  },
  {
    path: route2.path,
    breadcrumbName: route2.meta.title
  }
])
function refresh() {
  router.go(0)
}
function returnFun() {
  router.go(-1)
}
</script>
<style lang="less" scoped>
.nav_bar_img {
  img {
    width: 20px;
    cursor: pointer;
  }
}

.layouts {
  width: 1200px;
  margin: 0 auto;
}

.bread_box {
  width: 100%;
  height: 55px;
  background-color: #fff;

  .bread {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
  }
}
</style>