<template>
  <topbar />
  <navbar />
  <div class="bread_box margin-top">
    <div class="bread">
      <a-breadcrumb :routes="routes">
        <template #itemRender="{ route }">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{ route.breadcrumbName }}
          </span>
          <router-link v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
      <back />
    </div>
  </div>
  <div class="layouts">
    <router-view :key="route2.fullPath" />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import topbar from './components/topbar'
import navbar from './components/navbar'
import back from './components/back'
const route2 = useRoute()
const routes = ref([
  {
    path: '/dash/index',
    breadcrumbName: '会员中心'
  }, 
  {
    path: '/dash/index',
    breadcrumbName: '工作台',
    children: [
      {
        path: '/post',
        breadcrumbName: '我的发布'
      },
      {
        path: '/talk/index',
        breadcrumbName: '询价管理'
      }
    ]
  }, 
  {
    path: route2.path,
    breadcrumbName: route2.meta.title
  }
])
</script>
<style lang="less" scoped>
.layouts {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}
.bread_box {
  width: 100%;
  height: 55px;
  background-color: #fff;
  .bread {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
  }
}
</style>