import request from '@/utils/request'

export function getHomeCity(params) {
  return request({
    url: '/home/cityTree',
    method: 'get',
    params
  })
}

// home
export function homeLogin(data) {
  return request({
    url: '/home/login',
    method: 'post',
    data
  })
}
export function homeRegister(data) {
  return request({
    url: '/home/register',
    method: 'post',
    data
  })
}
export function homeForgotPasswd(data) {
  return request({
    url: '/home/forgotPasswd',
    method: 'post',
    data
  })
}
export function homeCaptcha(params) {
  return request({
    url: '/home/captcha',
    method: 'get',
    params
  })
}
export function getSmsCode(data) {
  return request({
    url: '/home/sendCode',
    method: 'post',
    data
  })
}
export function homeUserChangePass(data) {
  return request({
    url: '/home/user/changePassword',
    method: 'post',
    data
  })
}
export function homeUpload(data) {
  return request({
    url: '/home/pic',
    method: 'post',
    data
  })
}
export function homeUserCert(data) {
  return request({
    url: '/home/user/certificate',
    method: 'post',
    data
  })
}
export function homeUserCertInfo(params) {
  return request({
    url: '/home/user/certificateInfo',
    method: 'get',
    params
  })
}
export function UserChangePhoneOldSms(data) {
  return request({
    url: '/home/user/phone/oldSms',
    method: 'post',
    data
  })
}
export function UserChangePhoneNewSms(data) {
  return request({
    url: 'home/user/phone/newSms',
    method: 'post',
    data
  })
}
export function UserPhoneSave(data) {
  return request({
    url: '/home/user/phone/save',
    method: 'post',
    data
  })
}
export function homeLogout(data) {
  return request({
    url: '/home/user/logout',
    method: 'post',
    data
  })
}
export function homeUserAddressList(params) {
  return request({
    url: '/home/user/address/list',
    method: 'get',
    params
  })
}
export function homeWorkbenchIssue(params) {
  return request({
    url: '/home/workbench/issue',
    method: 'get',
    params
  })
}
export function homeWorkbenchCustomer(params) {
  return request({
    url: '/home/workbench/customer',
    method: 'get',
    params
  })
}
export function fileUpload(data) {
  return request({
    url: '/home/file',
    method: 'post',
    data
  })
}
export function homeFavoriteList(params) {
  return request({
    url: '/home/favorite/list',
    method: 'get',
    params
  })
}
export function homeFavoriteSave(data) {
  return request({
    url: '/home/favorite/save',
    method: 'post',
    data
  })
}
export function homeFavoriteCheck(params) {
  return request({
    url: '/home/favorite/check',
    method: 'get',
    params
  })
}
export function homeUserDispose(data) {
  return request({
    url: '/home/user/dispose',
    method: 'post',
    data
  })
}
export function homeUserSendDisposeCode(data) {
  return request({
    url: '/home/user/sendDisposeCode',
    method: 'post',
    data
  })
}
export function homeIndexBidding(params) {
  return request({
    url: '/home/index/index/bidding',
    method: 'get',
    params
  })
}
export function homeBannerList(params) {
  return request({
    url: '/home/banner/list',
    method: 'get',
    params
  })
}
export function homeIndexBottomNav(params) {
  return request({
    url: '/home/index/index/bottom_nav',
    method: 'get',
    params
  })
}
export function storeWord(params) {
  return request({
    url: '/home/word',
    method: 'get',
    params
  })
}
export function homeIndexCustomer(params) {
  return request({
    url: '/home/index/customer',
    method: 'get',
    params
  })
}
export function homeGetBlackAndWhiteModeInfo(params) {
  return request({
    url: '/home/getBlackAndWhiteModeInfo',
    method: 'get',
    params
  })
}

