<template>
  <div :class="{grayscale}" class="app_layout">
    <a-config-provider :locale="zhCN">
      <router-view :key="route.fullPath" />
    </a-config-provider>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import { homeGetBlackAndWhiteModeInfo } from '@/api/home'
import { mainStore } from '@/store/index'
import { useRoute } from 'vue-router'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
const route = useRoute()
const store = mainStore()
dayjs.locale('zh-cn')
store.getCityData()
store.getEquipData()
store.getFootList()
store.getServerInfo()
const grayscale = ref(false)
onBeforeMount(() => {
  homeGetBlackAndWhiteModeInfo().then(res => {
    grayscale.value = parseInt(res.data.black_and_white_mode)
  })
})
</script>
<style>
.grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}
.app_layout {
  height: 100%;
}
</style>
