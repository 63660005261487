<template>
  <topbar />
  <navbar />
  <div v-if="!route.params.id">
    <search />
  </div>
  <div>
    <router-view :key="route.fullPath" />
  </div>
  <pageFooter />
</template>
<script setup>
import search from '@/components/search'
import { useRoute, useRouter } from 'vue-router'
import topbar from './components/topbar'
import navbar from './components/navbar'
import pageFooter from '@/components/footer'

const route = useRoute()
const router = useRouter()

</script>
<style scoped lang="less">
.nav_pop_driver {
  width: 285px;
  height: 56px;
  display: flex;
  align-items: center;

  .item {
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .item:first-child {
    border-right: 1px solid #f2f2f2;
  }
}

.outicon {
  width: 13px;
  height: 15px;
  margin-bottom: 3px;
}

.nav_pop_content {
  width: 396px;
  height: 96px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 12px;

  .item {
    width: 130px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

.home_header_box {
  width: 100%;
  height: 80px;
  background: #fff;

  .home_header {
    width: 1205px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .home_header_layout {
      display: flex;
      align-items: center;
      margin-left: 30px;

      .item {
        margin-right: 40px;

        a {
          color: #1f1f1f;
          font-weight: 700;
          font-size: 16px;

          :active {
            color: #0A6AB9 !important;
          }
        }
      }

    }
  }
}</style>