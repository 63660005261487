<template>
  <div class="dressing_screening">
    <div class="lease_input">
      <div class="lease_frame" style="width: 540px;">
        <div>
          <a-select placeholder="租赁设备" :bordered="false">
            <a-select-option value="租赁设备" @click="router.push('/leaseinfo/index')">租赁设备</a-select-option>
            <a-select-option value="二手机" @click="router.push('/machine/index')">二手机</a-select-option>
            <a-select-option value="新机" @click="router.push('/newMachine/index')">新机</a-select-option>
            <a-select-option value="配件" @click="router.push('/Fittings/index')">配件</a-select-option>
          </a-select>
        </div>
        <div>
          <a-input
            v-model:value="keywords"
            placeholder="请输入关键词"
            style="width: 420px;"
            @keyup.enter="searchSubmit"
          />
        </div>
      </div>
      <div>
        <a-button type="primary" @click="searchSubmit">
          <template #icon> <img src="/images/sea.png" style="width: 24px;margin-right: 5px;"> </template>
          搜索
        </a-button>
      </div>
      <div>
        <a-button type="primary" class="publish" @click="router.push('/public/index')">免费发布信息</a-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
const router = useRouter()
const keywords = ref('')
function searchSubmit() {
  router.push({ path: '/leaseinfo/index', query: { keywords: keywords.value } })
}
</script>
<style lang="less" src="@/style/rent.less" scoped>
</style>
<style lang="less" scoped>
.dressing_screening {
  background: #EFF3FF !important;
  padding-bottom: 0;
  .lease_input {
    padding-bottom: 0;
  }
}

:deep .ant-select-selection-placeholder {
 color: #0A6AB9 !important;
}
</style>