import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '@/router'
import { getToken } from '@/utils/auth'

const whiteList = [
  'Login', 'Index', 'PublicIndex', 
  'LeaseinfoIndex','LeaseinfoDeta',
  'StoreinfoDeta', 'machineDeta',
  'machineIndex','MaShopDeta', '404',
  'FittIndex', 'FittDeta', 'Map', 'MapIndex', 'MessageIndex','helpdocu',
  'FittingDeta', 'newIndex', 'newDeta', 'newDetas', 'BiddingCompete', 'BiddingCompeteInfo',
  'BiddingEquip', 'BiddingEquipInfo', 'BiddingJob', 'BiddingJobInfo','HelpdocuIndex','TrendsIndex'
]

router.beforeEach((to, from, next) => {
  const hasToken = getToken()
  NProgress.start()
  if (hasToken) {
    if (to.name === 'Login') {
      router.replace('/dash/index')
    } else {
      next()
    } 
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})
router.afterEach(to => {
  NProgress.done()
  document.title = to.meta.title
})
