import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App'
import 'ant-design-vue/dist/antd.less'
import router from './router'
import './style/index.less'
import './permission'
import v3ImgPreview from 'v3-img-preview'
import animated from 'animate.css' 

const app = createApp(App).use(router)

// 引入pinia
import { createPinia } from 'pinia'
const pinia = createPinia()
app.use(pinia)
app.use(v3ImgPreview)

app.use(Antd).mount('#app')