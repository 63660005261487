import request from '@/utils/request'

export function homeJobTree(params) {
  return request({
    url: '/home/jobTree',
    method: 'get',
    params
  })
}
export function homeJobIndex(params) {
  return request({
    url: '/home/job/index',
    method: 'get',
    params
  })
}
export function homeJobAdd1(data) {
  return request({
    url: '/home/job/add/1',
    method: 'post',
    data
  })
}
export function homeJobAdd2(data) {
  return request({
    url: '/home/job/add/2',
    method: 'post',
    data
  })
}
export function homeUserIndex(params) {
  return request({
    url: '/home/user/index',
    method: 'get',
    params
  })
}
export function homeEquipTree(params) {
  return request({
    url: '/home/equipTree',
    method: 'get',
    params
  })
}
export function homePurchaseIndex(params) {
  return request({
    url: '/home/purchase/index',
    method: 'get',
    params
  })
}
export function homePurchase2(data) {
  return request({
    url: '/home/purchase/add/2',
    method: 'post',
    data
  })
}
export function homePurchase1(data) {
  return request({
    url: '/home/purchase/add/1',
    method: 'post',
    data
  })
}
export function homePurchase3(data) {
  return request({
    url: '/home/purchase/add/3',
    method: 'post',
    data
  })
}
export function homePurchase4(data) {
  return request({
    url: '/home/purchase/add/4',
    method: 'post',
    data
  })
}
export function homePurchase5(data) {
  return request({
    url: '/home/purchase/add/5',
    method: 'post',
    data
  })
}
export function homePurchase6(data) {
  return request({
    url: '/home/purchase/add/6',
    method: 'post',
    data
  })
}
export function homePurchaseDetail(params) {
  return request({
    url: '/home/purchase/detail',
    method: 'get',
    params
  })
}
export function homePartsTree(params) {
  return request({
    url: '/home/partsTree',
    method: 'get',
    params
  })
}
export function homeJobList(params) {
  return request({
    url: '/home/job/list',
    method: 'get',
    params
  })
}
export function homeJobDetail(params) {
  return request({
    url: '/home/job/detail',
    method: 'get',
    params
  })
}
export function homeJobEdit(data) {
  return request({
    url: `/home/job/edit/${data.id}`,
    method: 'post',
    data
  })
}
export function homeCitySecond(params) {
  return request({
    url: '/home/citySecond',
    method: 'get',
    params
  })
}
export function homeJobClose(data) {
  return request({
    url: '/home/job/close',
    method: 'post',
    data
  })
}
export function homeJobClose2(data) {
  return request({
    url: '/home/purchase/close',
    method: 'post',
    data
  })
}
export function homePurchaseList(params) {
  return request({
    url: '/home/purchase/list',
    method: 'get',
    params
  })
}
export function homePurchaseEdit(data) {
  return request({
    url: `/home/purchase/edit/${data.id}`,
    method: 'post',
    data
  })
}
export function homePoint(data) {
  return request({
    url: '/home/point',
    method: 'post',
    data
  })
}

export function homeWorkbenchBidding(params) {
  return request({
    url: '/home/workbench/bidding',
    method: 'get',
    params
  })
}
export function homeWorkbenchJobVitae(params) {
  return request({
    url: '/home/workbench/jobVitae',
    method: 'get',
    params
  })
}

