<template>
  <topbar />
  <navbar />
  <div class="bread_box margin-top">
    <div class="bread">
      <a-breadcrumb :routes="routes">
        <template #itemRender="{ route }">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{ route.breadcrumbName }}
          </span>
          <router-link v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
      <back />
    </div>
  </div>
  <div v-if="route2.meta.text" class="nav_bar">
    <div>
      <b>{{ route2.meta.text }}</b>
      <span v-if="route2.meta.type === 'form'" class="margin-left-xs">
        <span class="text-grey font-12">带<span class="text-red">*</span>号为必填</span>
      </span>
    </div>
    <div>
      <a-button v-if="route2.path==='/compete/list'" class="navbar_public_btn" @click="router.push('/compete/post')">发布竞价</a-button>
      <a-button v-else-if="route2.path === '/driver/list'" class="navbar_public_btn" @click="router.push('/driver/post')">发布招聘</a-button>
      <template v-else-if="route2.path === '/rental/list'">
        <a-button class="navbar_public_btn" @click="router.push('/rental/post')">发布出租</a-button>
        <a-button class="navbar_public_btn margin-left-xs" @click="router.push('/rental/seek')">发布求租</a-button>
      </template>
      <template v-else-if="route2.path === '/used/list'">
        <a-button class="navbar_public_btn" @click="router.push('/used/purchase')">发布出售</a-button>
        <a-button class="navbar_public_btn margin-left-xs" @click="router.push('/used/post')">发布求购</a-button>
      </template>
      <template v-else-if="route2.path === '/other/list'">
        <a-button class="navbar_public_btn" @click="router.push('/other/machine')">发布新机求购</a-button>
        <a-button class="navbar_public_btn margin-left-xs" @click="router.push('/other/match')">发布配件求购</a-button>
      </template>
    </div>
    <!-- <div class="nav_bar_img">
      <img src="/images/return.png" @click="returnFun">
      <img src="/images/refresh.png" class="margin-left" @click="refresh">
    </div> -->
  </div>
  <div class="layouts">
    <router-view :key="route2.fullPath" />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import topbar from './components/topbar'
import navbar from './components/navbar'
import back from './components/back'

const router = useRouter()
const route2 = useRoute()
const routes = ref([
  {
    path: '/dash/index',
    breadcrumbName: '会员中心'
  },
  {
    path: '/post',
    breadcrumbName: '我的发布',
    children: [
      {
        path: '/dash/index',
        breadcrumbName: '工作台'
      },
      {
        path: '/talk/index',
        breadcrumbName: '询价管理'
      }
    ]
  },
  {
    path: route2.path,
    breadcrumbName: route2.meta.title
  }
])
function refresh() {
  router.go(0)
}
function returnFun() {
  router.go(-1)
}
</script>
<style lang="less" scoped>
.navbar_public_btn {
  background-color: #F0F2F5 !important;
  color: #666;
}
.navbar_public_btn:hover {
  color: @color_main;
}
.nav_bar_img {
  img {
    width: 20px;
    cursor: pointer;
  }
}
.layouts {
  width: 1200px;
  margin: 0 auto;
}

.bread_box {
  width: 100%;
  height: 55px;
  background-color: #fff;

  .bread {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
  }
}
</style>