<template>
  <div class="map_header">
    <div class="map_header_container">
      <img src="/images/logo.png" class="logo" @click="router.push('/')">
      <navItem />
    </div>
  </div>
  <router-view :key="route.fullPath" />
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'
import navItem from '@/layouts/components/navMap'
const route = useRoute()
const router = useRouter()
</script>
<style lang="less" scoped>
.map_header {
  width: 100%;
  height: 44px;
  background-color: @color_main;
}
.map_header_container {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  .nav {
    display: flex;
    margin-left: 88px;
    color: #fff;
    .item {
      font-size: 16px;
      margin-right: 45px;
      cursor: pointer;
    }
  }
  .logo {
    width: 114px;
    cursor: pointer;
  }
}
</style>