<template>
  <topbar />
  <div>
    <router-view :key="route.fullPath" />
  </div>
  <pageFooter />
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'
import topbar from './components/topbar'
import pageFooter from '@/components/footer'

const route = useRoute()
const router = useRouter()
</script>
