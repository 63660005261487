<template>
  <div class="home_header_layout" style="color:#fff !important">
    <div class="item">
      <router-link to="/">首页</router-link>
    </div>
    <div class="item">
      <router-link to="/rent">租赁</router-link>
    </div>
    <div class="item">
      <router-link to="/bidding/compete">竞价</router-link>
    </div>
    <div class="item">
      <router-link to="/machine">二手机</router-link>
    </div>
    <div class="item">
      <router-link to="/newMachine">新机</router-link>
    </div>
    <div class="item">
      <router-link to="/fittings">云商</router-link>
    </div>
    <a-popover trigger="hover" placement="bottom">
      <template #content>
        <div class="nav_pop_driver">
          <div class="item">
            <span @click="toDriver(1)">机手招聘</span>
          </div>
          <div class="item">
            <span @click="toDriver(2)">求职简历</span>
          </div>
        </div>
      </template>
      <div class="item">
        <a>机手 <img class="outicon" src="/images/u68.svg"></a>
      </div>
    </a-popover>
    <a-popover trigger="hover" placement="bottom">
      <template #content>
        <div class="nav_pop_content">
          <div class="item" @click="toBidding(2)">设备出租</div>
          <div class="item" @click="toBidding(4)">二手机出售</div>
          <div class="item" @click="toBidding(3)">新机求购</div>
          <div class="item" @click="toBidding(1)">设备求租</div>
          <div class="item" @click="toBidding(5)">二手机求购</div>
          <div class="item" @click="toBidding(6)">配件求购</div>
        </div>
      </template>
      <div class="item">
        <a>需求大厅 <img class="outicon" src="/images/u68.svg"></a>
      </div>
    </a-popover>
    <div class="item">
      <router-link to="/trends">资讯动态</router-link>
    </div>
    <a-popover trigger="hover" placement="bottom">
      <div class="item">
        <a @click="IoTcontrol">物联管理</a>
      </div>
    </a-popover>
    <div class="item">
      <router-link to="/helpdocu/index?id=1">帮助中心</router-link>
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const props = defineProps({
  mode: {
    type: String,
    default: 'white'
  }
})

function IoTcontrol(){
  window.open(process.env.VUE_APP_IOT_URL)
}
function toDriver(type) {
  if (route.name === 'BiddingJob' && parseInt(route.query.type) === type && Object.keys(route.query).length <= 1) {
    return
  }
  router.push(`/bidding/job?type=${type}`)
}

function toBidding(type) {
  if (route.name === 'BiddingEquip' && parseInt(route.query.type) === type && Object.keys(route.query).length <= 1) {
    return
  }
  router.push(`/bidding/equip?type=${type}`)
}
</script>
<style scoped lang="less">
a {
  color: #fff;
}

.nav_pop_driver {
  width: 285px;
  height: 56px;
  display: flex;
  align-items: center;

  .item {
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .item:first-child {
    border-right: 1px solid #f2f2f2;
  }
}

.outicon {
  width: 13px;
  margin-bottom: 3px;
}

.nav_pop_content {
  width: 396px;
  height: 96px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 12px;

  .item {
    width: 130px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

.home_header_layout {
  display: flex;
  align-items: center;
  margin-left: 30px;

  .item {
    margin-right: 40px;

    a {
      font-weight: 700;
      font-size: 16px;
    }
    a:hover {
      color: #fff;
    }
  }

}
</style>