<template>
  <div class="home_header_layout" style="color:#fff !important">
    <div class="item">
      <router-link to="/" :class="{active:route.path === '/index'}">首页</router-link>
    </div>
    <div class="item">
      <router-link to="/rent" :class="{active:route.path==='/leaseinfo/index'||route.meta.activeMenu==='/leaseinfo/index'}">租赁</router-link>
    </div>
    <div class="item">
      <router-link to="/bidding/compete" :class="{ active: route.path === '/bidding/compete'||route.meta.activeMenu==='/bidding/compete' }">竞价</router-link>
    </div>
    <div class="item">
      <router-link to="/machine" :class="{ active: route.path === '/machine/index'||route.meta.activeMenu==='/machine/index' }">二手机</router-link>
    </div>
    <div class="item">
      <router-link to="/newMachine" :class="{ active: route.path === '/newMachine/index'||route.meta.activeMenu==='/newMachine/index' }">新机</router-link>
    </div>
    <div class="item">
      <router-link to="/fittings" :class="{ active: route.path === '/Fittings/index'||route.meta.activeMenu==='/Fittings/index' }">云商</router-link>
    </div>
    <a-popover trigger="hover" placement="bottom">
      <template #content>
        <div class="nav_pop_driver">
          <div class="item" :class="{active: (route.path === '/bidding/job' && route.query.type === '1') || (route.meta.activeMenu === '/bidding/job' && route.query.type === '7') }">
            <span @click="toDriver(1)">机手招聘</span>
          </div>
          <div class="item" :class="{active: (route.path === '/bidding/job' && route.query.type === '2') || (route.meta.activeMenu === '/bidding/job' && route.query.type === '6') }">
            <span @click="toDriver(2)">求职简历</span>
          </div>
        </div>
      </template>
      <div class="item">
        <a :class="{active: route.path === '/bidding/job' || route.meta.activeMenu === '/bidding/job' }">机手 <img class="outicon" src="/images/outicon.png"></a>
      </div>
    </a-popover>
    <a-popover trigger="hover" placement="bottom">
      <template #content>
        <div class="nav_pop_content">
          <div class="item" :class="{active:(route.path === '/bidding/equip' && route.query.type === '2') || (route.meta.activeMenu === '/bidding/equip' && route.query.type === '8') }" @click="toBidding(2)">设备出租</div>
          <div class="item" :class="{active:(route.path === '/bidding/equip' && route.query.type === '4') || (route.meta.activeMenu === '/bidding/equip' && route.query.type === '13')}" @click="toBidding(4)">二手机出售</div>
          <div class="item" :class="{active:(route.path === '/bidding/equip' && route.query.type === '3') || (route.meta.activeMenu === '/bidding/equip' && route.query.type === '10')}" @click="toBidding(3)">新机求购</div>
          <div class="item" :class="{active:(route.path === '/bidding/equip' && route.query.type === '1') || (route.meta.activeMenu === '/bidding/equip' && route.query.type === '9')}" @click="toBidding(1)">设备求租</div>
          <div class="item" :class="{active:(route.path === '/bidding/equip' && route.query.type === '5') || (route.meta.activeMenu === '/bidding/equip' && route.query.type === '12')}" @click="toBidding(5)">二手机求购</div>
          <div class="item" :class="{active:(route.path === '/bidding/equip' && route.query.type === '6') || (route.meta.activeMenu === '/bidding/equip' && route.query.type === '11')}" @click="toBidding(6)">配件求购</div>
        </div>
      </template>
      <div class="item">
        <a :class="{active: route.path === '/bidding/equip'||route.meta.activeMenu==='/bidding/equip'}">需求大厅 <img class="outicon" src="/images/outicon.png"></a>
      </div>
    </a-popover>
    <div class="item">
      <router-link to="/trends" :class="{active: route.path==='/trends/index'}">资讯动态</router-link>
    </div>
    <a-popover trigger="hover" placement="bottom">
      <div class="item">
        <a @click="IoTcontrol">物联管理</a>
      </div>
    </a-popover>
    <div class="item">
      <router-link to="/helpdocu/index?id=1">帮助中心</router-link>
    </div>
  </div>
</template>
<script setup>
import { ref,watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const isShow = ref(router.currentRoute.value.query.isShow)
const props = defineProps({
  mode: {
    type: String,
    default: 'white'
  }
})
function IoTcontrol(){
  window.open(process.env.VUE_APP_IOT_URL)
}
function toDriver(type) {
  if (route.name === 'BiddingJob' && parseInt(route.query.type) === type && Object.keys(route.query).length <= 1) {
    return
  }
  if(type === 1){
    isShow.value = 7
  } else {
    isShow.value = 6
  }
  router.push(`/bidding/job?type=${type}`)
}

function toBidding(type) {
  if (route.name === 'BiddingEquip' && parseInt(route.query.type) === type && Object.keys(route.query).length <= 1) {
    return
  }
  if(type === 1){
    isShow.value = 9
  } else if(type === 2) {
    isShow.value = 8
  }else if(type === 3) {
    isShow.value = 10
  }else if(type === 4) {
    isShow.value = 13
  }else if(type === 5) {
    isShow.value = 12
  }else if(type === 6) {
    isShow.value = 11
  }
  router.push(`/bidding/equip?type=${type}`)
}
</script>
<style scoped lang="less">
a {
  color: #1f1f1f;
}
.nav_pop_driver {
  width: 285px;
  height: 56px;
  display: flex;
  align-items: center;

  .item {
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  .item:first-child {
    border-right: 1px solid #f2f2f2;
  }
}

.outicon {
  width: 13px;
  height: 15px;
  margin-bottom: 3px;
}

.nav_pop_content {
  width: 396px;
  height: 96px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 12px;

  .item {
    width: 130px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

.home_header_layout {
  display: flex;
  align-items: center;
  margin-left: 30px;

  .item {
    margin-right: 40px;

    a {
      font-weight: 700;
      font-size: 16px;

      :active {
        color: @color_main !important;
      }
    }
  }

}
.active{
  color: #0A6AB9;
}
.blu{
  color: #0A6AB9 !important;
}
.whi{
  color: #000 !important;
}
</style>