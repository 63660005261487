<template>
  <div class="home_navbar_box">
    <div class="home_navbar">
      <div class="left">
        <span>您好，欢迎来到楚道云租</span>
        <div class="item_box">
          <div class="item">
            <router-link to="/index">首页</router-link>
          </div>
          <div class="item">
            <router-link to="/map/index">地图搜索</router-link>
          </div>
          <!-- <div class="item">
            <router-link to="/index">购物车</router-link>
          </div> -->
          <a-dropdown>
            <div class="item" style="padding:0 30px">
              <router-link to="/dash/index">
                会员中心
                <span class="font-10">
                  <DownOutlined />
                </span>
              </router-link>
            </div>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <router-link to="/dash/index" :class="{ active: route.path === '/dash/index' }" class="t_menu_item">工作台</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link to="/post/index" :class="{ active: route.path === '/post/index' }" class="t_menu_item">我的发布</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link to="/talk/index" :class="{ active: route.path === '/talk/index' }" class="t_menu_item">询价管理</router-link>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <template v-if="!hasToken">
            <div class="item">
              <router-link to="/login?mode=regist">会员注册</router-link>
            </div>
            <div class="item">
              <router-link :to="`/login?from=${route.fullPath}`">会员登录</router-link>
            </div>
          </template>
          <div v-else class="flex align-center">
            <template v-if="store.userInfo.phone">
              <div class="item pointer" @click="toDash">{{ phoneNumberHide(store.userInfo.phone) }}</div>
              <!-- <div class="margin-left-xs margin-right-xs">|</div> -->
            </template>
            <div class="item pointer" @click="logout">退出</div>
          </div>
          <!-- <div class="item pointer" @click="toStore('sign')">
            会员注册
          </div>
          <div class="item pointer" @click="toStore('login')">
            会员登录
          </div> -->
          <!-- <div class="item pointer" @click="toStore('sign')">
            商家入驻
          </div>
          <div class="item pointer" @click="toStore('login')">
            商家登录
          </div> -->
          <!-- <a-dropdown>
            <div class="item pointer">
              <div>客户服务</div>
            </div>
            <template #overlay>
              <div class="drop_plane">
                <img src="/images/server.png">
                <b>{{ store.serverInfo.platform_customer_service }}</b>
                <span>客服时间：{{ store.serverInfo.customer_service_time }}</span>
              </div>
            </template>
          </a-dropdown> -->
        </div>
      </div>
      <div class="right">
        <div class="pointer" style="padding: 0 20px" @click="toStore('sign')">
          商家注册
        </div>
        <div class="pointer" style="padding: 0 20px;border-left: 1px solid #fff;" @click="toStore('login')">
          商家登录
        </div>
        <a-dropdown>
          <div class="item pointer" style="margin-left: 0;">
            <div>客户服务</div>
          </div>
          <template #overlay>
            <div class="drop_plane">
              <img src="/images/server.png">
              <b>{{ store.serverInfo.platform_customer_service }}</b>
              <span>客服时间：{{ store.serverInfo.customer_service_time }}</span>
            </div>
          </template>
        </a-dropdown>
        <!-- <template v-if="!hasToken">
          <div class="item">
            <router-link :to="`/login?from=${route.fullPath}`">登录</router-link>
          </div>
          <div class="item">
            <router-link to="/login?mode=regist">注册</router-link>
          </div>
        </template>
        <div v-else class="flex align-center">
          <template v-if="store.userInfo.phone">
            <div class="pointer" @click="toDash">{{ phoneNumberHide(store.userInfo.phone) }}</div>
            <div class="margin-left-xs margin-right-xs">|</div>
          </template>
          <div class="pointer" @click="logout">退出</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { homeLogout } from '@/api/home'
import { Modal } from 'ant-design-vue'
import { removeToken } from '@/utils/auth'
import { getToken } from '@/utils/auth'
import { useRouter, useRoute } from 'vue-router'
import { phoneNumberHide } from '@/utils/auth'
import { mainStore } from '@/store'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'

const hasToken = getToken()
const router = useRouter()
const route = useRoute()
const store = mainStore()

if (hasToken) {
  store.getUserInfo()
}

function toDash() {
  router.push('/dash/index')
}

function toStore(page) {
  if (page === 'sign') {
    window.open(process.env.VUE_APP_STORE_URL + 'sign/index')
  } else if (page === 'login') {
    window.open(process.env.VUE_APP_STORE_URL + 'login')
  }
}

function logout() {
  Modal.confirm({
    content: '是否确认退出登录?',
    onOk() {
      homeLogout().then(() => {
        removeToken()
        router.go(0)
      })
    }
  })
}
</script>
<style lang="less" scoped>
.drop_plane {
  width: 242px;
  height: 168px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px #0000003F;
  position: relative;
  top: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 36px;
    margin-top: 30px;
  }
  b {
    font-size: 20px;
    color: @color_main;
    margin-top: 5px;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
}
.t_menu_item {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.t_menu_item.active, .t_menu_item:hover {
  color: @color_main;
}
.home_navbar_box {
  width: 100%;
  height: 40px;
  background-color: #1f1f1f;
  color: #fff;

  .home_navbar {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    .left {
      display: flex;
      align-items: center;

      .item_box {
        display: flex;
        align-items: center;
        margin-left: 20px;

        .item {
          padding: 0 20px;
          border-left: 1px solid #fff;
        }
      }

      a {
        color: #fff;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .item:last-child {
        border-left: 1px solid #fff;
        margin-left: 10px;
        padding-left: 10px;
      }

      a {
        color: #fff;
      }
    }
  }
}
</style>
<style lang="less">
.ant-dropdown-content:has(.t_menu_item) {
  position: relative;
  top: 7px;
  .ant-dropdown-menu-item:hover {
    background-color: #fff;
  }
}
</style>