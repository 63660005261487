import { createRouter, createWebHashHistory } from 'vue-router'
import Layouts from '@/layouts/index'
import MapLayouts from '@/layouts/map'
import PostLayouts from '@/layouts/post'
import TalkLayouts from '@/layouts/talk'
import SearchLayouts from '@/layouts/search'
import HomeLayouts from '@/layouts/home'
import IndexLayouts from '@/layouts/layouts'

const routes = [
  {
    path: '/dash',
    name: 'Dash',
    component: Layouts,
    redirect: '/dash/index',
    children: [
      {
        path: 'index',
        name: 'DashIndex',
        meta: { title: '工作台', page_type: 'user' },
        component: () => import('@/views/index/index')
      },
      {
        path: 'logoff',
        name: 'DashLogoff',
        meta: { title: '注销账号', page_type: 'user' },
        component: () => import('@/views/index/logoff')
      }
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Layouts,
    redirect: '/auth/index',
    children: [
      {
        path: 'index',
        name: 'AuthIndex',
        meta: { title: '实名认证', page_type: 'user' },
        component: () => import('@/views/auth/index')
      },
      {
        path: 'person',
        name: 'AuthPerson',
        meta: { title: '个人实名认证', page_type: 'user' },
        component: () => import('@/views/auth/form')
      },
      {
        path: 'company',
        name: 'AuthCompany',
        meta: { title: '企业实名认证', page_type: 'user' },
        component: () => import('@/views/auth/form')
      }
    ]
  },
  {
    path: '/post',
    name: 'Post',
    component: PostLayouts,
    redirect: '/post/index',
    children: [
      {
        path: 'index',
        name: 'PostIndex',
        meta: { title: '我的发布', page_type: 'user' },
        component: () => import('@/views/index/post')
      },
      {
        path: 'report',
        name: 'PostReport',
        meta: { title: '选择分类', page_type: 'user' },
        component: () => import('@/views/index/report')
      }
    ]
  },
  {
    path: '/comment',
    name: 'Comment',
    component: PostLayouts,
    redirect: '/comment/index',
    children: [
      {
        path: 'index',
        name: 'CommentIndex',
        meta: { title: '留言管理', page_type: 'user' },
        component: () => import('@/views/quick/message/index')
      }
    ]
  },
  {
    path: '/report',
    name: 'Report',
    component: PostLayouts,
    redirect: '/report/index',
    children: [
      {
        path: 'index',
        name: 'ReportIndex',
        meta: { title: '我的举报', page_type: 'user' },
        component: () => import('@/views/quick/report/index')
      }
    ]
  },
  {
    path: '/talk',
    name: 'Talk',
    component: TalkLayouts,
    redirect: '/talk/index',
    children: [
      {
        path: 'index',
        name: 'TalkIndex',
        meta: { title: '询价管理', page_type: 'user' },
        component: () => import('@/views/quick/talk/index')
      },
      {
        path: 'submit',
        name: 'Submit',
        meta: { title: '提交询价单', page_type: 'user' },
        component: () => import('@/views/quick/talk/submit')
      },
      {
        path: 'info/:id',
        name: 'Info',
        meta: { title: '查看询价单', page_type: 'user' },
        component: () => import('@/views/quick/talk/info')
      }
    ]
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: Layouts,
    redirect: '/favorite/index',
    children: [
      {
        path: 'index',
        name: 'FavoriteIndex',
        meta: { title: '商品收藏', text: '商品收藏列表', page_type: 'user' },
        component: () => import('@/views/favorite/index')
      }
    ]
  },
  {
    path: '/address',
    name: 'Address',
    component: Layouts,
    redirect: '/address/index',
    children: [
      {
        path: 'index',
        name: 'AddressIndex',
        meta: { title: '收货地址', page_type: 'user' },
        component: () => import('@/views/home/address')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/sign/login'),
    meta: { title: '登录' }
  },
  {
    path: '/driver',
    name: 'Driver',
    component: PostLayouts,
    redirect: '/driver/list',
    children: [
      {
        path: 'list',
        name: 'DriverList',
        component: () => import('@/views/driver/list'),
        meta: { title: '机手招聘信息', text: '机手招聘信息列表', page_type: 'user' }
      },
      {
        path: 'info/:id',
        name: 'DriverInfo',
        component: () => import('@/views/driver/info'),
        meta: { title: '机手招聘信息', text: '机手招聘信息详情', page_type: 'user' }
      },
      {
        path: 'edit/:id',
        name: 'DriverEdit',
        component: () => import('@/views/driver/post'),
        meta: { title: '机手招聘信息', text: '修改机手招聘信息', page_type: 'user' }
      },
      {
        path: 'post',
        name: 'DriverPost',
        component: () => import('@/views/driver/post'),
        meta: { title: '发布机手招聘', text: '发布机手招聘信息', page_type: 'user' }
      }
    ]
  },
  {
    path: '/resume',
    name: 'Resume',
    component: PostLayouts,
    redirect: '/resumt/list',
    children: [
      {
        path: 'list',
        name: 'ResumeList',
        component: () => import('@/views/resume/list'),
        meta: { title: '求职简历', text: '我的求职简历', page_type: 'user' }
      },
      {
        path: 'post',
        name: 'ResumePost',
        component: () => import('@/views/resume/post'),
        meta: { title: '发布求职简历', text: '发布求职简历信息', page_type: 'user' }
      },
      {
        path: 'edit/:id',
        name: 'ResumeEdit',
        component: () => import('@/views/resume/post'),
        meta: { title: '修改求职简历', text: '修改求职简历信息', page_type: 'user' }
      }
    ]
  },
  {
    path: '/compete',
    name: 'Compete',
    component: PostLayouts,
    redirect: '/compete/list',
    children: [
      {
        path: 'list',
        name: 'CompeteList',
        component: () => import('@/views/compete/list'),
        meta: { title: '竞价求租信息', text: '竞价求租信息列表', page_type: 'user' }
      },
      {
        path: 'post',
        name: 'CompetePost',
        component: () => import('@/views/compete/post'),
        meta: { title: '发布竞价求租', text: '发布竞价求租信息', type: 'form', page_type: 'user' }
      },
      {
        path: 'edit/:id',
        name: 'CompeteEdit',
        component: () => import('@/views/compete/post'),
        meta: { title: '修改竞价求租', text: '修改竞价求租信息', type: 'form', page_type: 'user' }
      },
      {
        path: 'info/:id',
        name: 'CompeteInfo',
        component: () => import('@/views/compete/info'),
        meta: { title: '竞价求租详情', text: '竞价求租需求详情', page_type: 'user' }
      },
      {
        path: 'select/:id',
        name: 'CompeteSelect',
        component: () => import('@/views/compete/select'),
        meta: { title: '竞价求租选标', text: '竞价求租选标', page_type: 'user' }
      },
      {
        path: 'offer/:id',
        name: 'CompeteOffer',
        component: () => import('@/views/compete/offer'),
        meta: {
          title: '竞价报价详情', text: '竞价报价详情', page_type: 'user' }
      }
    ]
  },
  {
    path: '/rental',
    name: 'Rental',
    component: PostLayouts,
    redirect: '/rental/list',
    children: [
      {
        path: 'list',
        name: 'RentalList',
        component: () => import('@/views/rental/list'),
        meta: { title: '租赁需求信息', text: '租赁需求信息列表', page_type: 'user' }
      },
      {
        path: 'edit/:id',
        name: 'RentalEdit',
        component: () => import('@/views/rental/post'),
        meta: { title: '设备出租信息', text: '修改设备出租信息', page_type: 'user' }
      },
      {
        path: 'seekedit/:id',
        name: 'SeekEdit',
        component: () => import('@/views/rental/seek'),
        meta: { title: '设备求租信息', text: '修改设备求租信息', page_type: 'user' }
      },
      {
        path: 'post',
        name: 'RentalPost',
        component: () => import('@/views/rental/post'),
        meta: { title: '发布设备出租', text: '发布设备出租信息', page_type: 'user' }
      },
      {
        path: 'seek',
        name: 'SeekPost',
        component: () => import('@/views/rental/seek'),
        meta: { title: '发布设备求租', text: '发布设备求租信息', page_type: 'user' }
      },
      {
        path: 'info/:id',
        name: 'RentalInfo',
        component: () => import('@/views/rental/info'),
        meta: { title: '设备出租信息', text: '设备出租信息详情', page_type: 'user' }
      },
      {
        path: 'seekinfo/:id',
        name: 'SeekInfo',
        component: () => import('@/views/rental/seekinfo'),
        meta: { title: '设备求租信息', text: '设备求租信息详情', page_type: 'user' }
      }
    ]
  },
  {
    path: '/other',
    name: 'Other',
    component: PostLayouts,
    redirect: '/other/list',
    children: [
      {
        path: 'list',
        name: 'OtherList',
        component: () => import('@/views/other/list'),
        meta: { title: '其他需求信息', text: '其他需求信息列表', page_type: 'user' }
      },
      {
        path: 'match',
        name: 'MatchPost',
        component: () => import('@/views/other/match'),
        meta: { title: '发布配件求购', text: '发布配件求购信息', page_type: 'user' }
      },
      {
        path: 'matchedit/:id',
        name: 'MatchEdit',
        component: () => import('@/views/other/match'),
        meta: { title: '配件求购信息', text: '修改配件求购信息', page_type: 'user' }
      },
      {
        path: 'matchinfo/:id',
        name: 'MatchInfo',
        component: () => import('@/views/other/matchinfo'),
        meta: { title: '配件求购信息', text: '配件求购信息详情', page_type: 'user' }
      },
      {
        path: 'machine',
        name: 'MachinePost',
        component: () => import('@/views/other/machine'),
        meta: { title: '发布新机求购', text: '发布新机求购信息', page_type: 'user' }
      },
      {
        path: 'machineedit/:id',
        name: 'MachineEdit',
        component: () => import('@/views/other/machine'),
        meta: { title: '新机求购信息', text: '修改新机求购信息', page_type: 'user' }
      },
      {
        path: 'machineinfo/:id',
        name: 'MachineInfo',
        component: () => import('@/views/other/machineinfo'),
        meta: { title: '新机求购信息', text: '新机求购信息详情', page_type: 'user' }
      }
    ]
  },
  {
    path: '/used',
    name: 'Used',
    component: PostLayouts,
    redirect: '/used/list',
    children: [
      {
        path: 'list',
        name: 'UsedList',
        component: () => import('@/views/used/list'),
        meta: { title: '二手机需求信息', text: '二手机需求信息列表', page_type: 'user' }
      },
      {
        path: 'post',
        name: 'UsedPost',
        component: () => import('@/views/used/post'),
        meta: { title: '发布二手机求购', text: '发布二手机求购信息', page_type: 'user' }
      },
      {
        path: 'info/:id',
        name: 'UsedInfo',
        component: () => import('@/views/used/info'),
        meta: { title: '二手机求购信息', text: '二手机求购信息详情', page_type: 'user' }
      },
      {
        path: 'edit/:id',
        name: 'UsedEdit',
        component: () => import('@/views/used/post'),
        meta: { title: '二手机求购信息', text: '修改二手机求购信息', page_type: 'user' }
      },
      {
        path: 'purchaseedit/:id',
        name: 'PurchaseEdit',
        component: () => import('@/views/used/purchase'),
        meta: { title: '二手机出售信息', text: '修改二手机出售信息', page_type: 'user' }
      },
      {
        path: 'purchase',
        name: 'PurchasePost',
        component: () => import('@/views/used/purchase'),
        meta: { title: '发布二手机出售', text: '发布二手机出售信息', page_type: 'user' }
      },
      {
        path: 'purchaseinfo/:id',
        name: 'PurchaseInfo',
        component: () => import('@/views/used/purchaseinfo'),
        meta: { title: '二手机出售信息', text: '二手机出售信息详情', page_type: 'user' }
      }
    ]
  }
]
export const homeRoutes = [
  {
    path: '/map',
    name: 'Map',
    component: MapLayouts,
    redirect: '/map/index',
    children: [
      {
        path: 'index',
        name: 'MapIndex',
        meta: { title: '地图搜索' },
        component: () => import('@/views/map/index')
      }
    ]
  },
  {
    path: '/bidding',
    name: 'Bidding',
    component: SearchLayouts,
    redirect: '/bidding/equip?type=1',
    children: [
      {
        path: 'compete',
        name: 'BiddingCompete',
        meta: { title: '竞价求租' },
        component: () => import('@/views/home/compete/list')
      },
      {
        path: 'competeinfo/:id',
        name: 'BiddingCompeteInfo',
        meta: { title: '竞价求租', activeMenu: '/bidding/compete' },
        component: () => import('@/views/home/compete/info')
      },
      {
        path: 'job',
        name: 'BiddingJob',
        meta: { title: '机手信息' },
        component: () => import('@/views/home/bidding/list')
      },
      {
        path: 'jobinfo/:id',
        name: 'BiddingJobInfo',
        meta: { title: '机手信息', activeMenu: '/bidding/job' },
        component: () => import('@/views/home/bidding/info')
      },
      {
        path: 'equip',
        name: 'BiddingEquip',
        meta: { title: '需求大厅' },
        component: () => import('@/views/home/bidding/list')
      },
      {
        path: 'equipinfo/:id',
        name: 'BiddingEquipInfo',
        meta: { title: '需求大厅', activeMenu: '/bidding/equip' },
        component: () => import('@/views/home/bidding/info')
      }
    ]
  },
  {
    path: '/',
    name: 'Home',
    component: IndexLayouts,
    redirect: '/index',
    children: [
      {
        path: 'index',
        name: 'Index',
        meta: { title: '首页' },
        component: () => import('@/views/home/index/index')
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404'),
    meta: { title: '404' }
  },
  {
    path: '/rent',
    name: 'Rent',
    component: HomeLayouts,
    redirect: '/leaseinfo/index',
    children: [
      {
        path: '/leaseinfo/index',
        name: 'LeaseinfoIndex',
        meta: { title: '租赁信息' },
        component: () => import('@/views/home/leaseinfo/index')
      },
      {
        path: '/leaseinfo/:id',
        name: 'LeaseinfoDeta',
        meta: { title: '租赁信息详情', activeMenu: '/leaseinfo/index' },
        component: () => import('@/views/leaseinfo/index')
      }
    ]
  },
  {path: '/storeinfo/:id', name: 'StoreinfoDeta',meta: { title: '店铺信息详情' },component: () => import('@/views/leaseinfo/shop')},
  {
    path: '/machine',
    name: 'Machine',  
    component: HomeLayouts,
    redirect: '/machine/index',
    children: [
      {
        path: '/machine/index',
        name: 'machineIndex',
        meta: { title: '二手机信息' },
        component: () => import('@/views/home/machine/index')
      },
      {
        path: '/machinfo/:id',
        name: 'machineDeta',
        meta: { title: '二手机信息详情', activeMenu: '/machine/index' },
        component: () => import('@/views/machine/index')
      }
    ]
  },
  {path: '/machineDe/:id', name: 'MaShopDeta',meta: { title: '店铺信息详情' },component: () => import('@/views/machine/shop')},
  {
    path: '/fittings',
    name: 'Fittings',
    component: HomeLayouts,
    redirect: '/Fittings/index',
    children: [
      {
        path: '/Fittings/index',
        name: 'FittIndex',
        meta: { title: '配件信息' },
        component: () => import('@/views/home/fittings/index')
      },
      {
        path: '/Fittingsinfo/:id',
        name: 'FittDeta',
        meta: { title: '配件信息详情', activeMenu: '/Fittings/index' },
        component: () => import('@/views/fittings/index')
      }
    ]
  },
  {path: '/FittingsDe/:id', name: 'FittingDeta',meta: { title: '店铺信息详情' },component: () => import('@/views/fittings/shop')},
  {
    path: '/newMachine',
    name: 'newMachine',
    component: HomeLayouts,
    redirect: '/newMachine/index',
    children: [
      {
        path: '/newMachine/index',
        name: 'newIndex',
        meta: { title: '新机信息' },
        component: () => import('@/views/home/newMachine/index')
      },
      {
        path: '/newinfo/:id',
        name: 'newDeta',
        meta: { title: '新机信息详情', activeMenu: '/newMachine/index' },
        component: () => import('@/views/newMachine/index')
      }
    ]
  },
  {path: '/newDe/:id', name: 'newDetas',meta: { title: '店铺信息详情' },component: () => import('@/views/newMachine/shop')},
  {
    path: '/helpdocu',
    name: 'Helpdocu',
    component: HomeLayouts,
    redirect: '/helpdocu/index',
    children: [
      {
        path: '/helpdocu/index',
        name: 'HelpdocuIndex',
        meta: { title: '帮助文档' },
        component: () => import('@/views/helpdocu/index')
      }
    ]
  },
  {
    path: '/trends',
    name: 'Trends',
    component: HomeLayouts,
    redirect: '/trends/index',
    children: [
      {
        path: '/trends/index',
        name: 'TrendsIndex',
        meta: { title: '资讯动态' },
        component: () => import('@/views/home/trends/index')
      }
    ]
  },
  {
    path: '/public',
    name: 'Public',
    component: HomeLayouts,
    redirect: '/public/index',
    children: [
      {
        path: 'index',
        name: 'PublicIndex',
        component: () => import('@/views/home/public/index'),
        meta: { title: '发布信息' }
      }
    ]
  },
  { path: '/:pathMatch(.*)', meta: { title: '404' }, redirect: '/404' }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes.concat(homeRoutes)
})

export default router
